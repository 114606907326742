.txt__color {
  &--black {
    h1, h2, h3, h4, h5, h6, p, a:not(.btn) {
      color: #173447;
    }
  }

  &--white {
    h1, h2, h3, h4, h5, h6, p, a:not(.btn) {
      color: #fff;
    }
  }
}
