.table {
  thead {
    th {
      border-bottom-color: rgba(0,0,0,.1);
    }
  }

  td,
  th {
    border-top-color: rgba(0,0,0,.1);
  }
}

.table-provider-width {
  max-width: 80em;
}

.td-provider-text {
  text-align: justify;
}

@media screen and (max-width: 120em) {
		.td-provider-normal-text {
			text-align: center;
		}

}

@media screen and (max-width: 42em) {
		.table-provider-slim-display, .tbody-provider-slim-display, .tr-provider-slim-display {
			display: block;
		}
		.thead-provider-slim-display {
			display: none;
		}
		.tr-provder-slim-border-top:first-child {
			border-top: 2px solid lightblue;
		}
		.tr-provder-slim-border-bottom {
			border-bottom: 2px solid lightblue;
			padding: .5em 2em 1em;
		}
		.td-provider-slim-content-one::before {
			content: var(--colheader1);
		}
		.td-provider-slim-content-two::before {
			content: var(--colheader2);
		}
		.td-provider-slim-content-three::before {
			content: var(--colheader3);
		}
		.td-provider-slim-content-four::before {
			content: var(--colheader4);
		}
		.td-provider-slim-content-five:before {
			content: var(--colheader5);
		}
		.tbody-provider-slim .th-provider-slim {
			display: block;
			font-weight: bold;
		}
		.td-provider-slim {
			display: inline-block;
			min-width: 30%;
			padding: .2em 2em;
			text-align: left;
		}

}

@media screen and (max-width: 33em) {
		.td-provider-superslim-display {
			display: block;
		}
		.tr-provider-superslim-padding {
			padding: .5em .5em 1em;
		}

}

.table-reseller-qualification {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333%;
  flex: 0 0 8.333%;
  max-width: 8.333%;
  position: relative;
  width: 100%;
}
