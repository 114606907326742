.hero-ml {
  margin-left: 4.5rem;
}

.hero-my {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}

.hero-div-left {
  width: 60%;
}

.hero-div-right {
  width: 40%;
}

.hero-height {
  height: 31.25rem;
}
