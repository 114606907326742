@media screen and (max-width: 64em) {
  .btn {
    font-size: 1.025rem;
    padding: 1.15rem 2.15rem
  }
  .btn-sm {
    font-size: 0.775rem;
    padding: 0.2rem 0.45rem;
  }
  .btn.btn-filter {
    font-size: 0.9rem;
  }

}

@media screen and (max-width: 50em) {
  .btn {
    font-size: 0.925rem;
    padding: 1.05rem 2.05rem;
  }
  .btn-sm {
    font-size: 0.675rem;
    padding: 0.15rem 0.4rem;
  }
  .btn.btn-filter {
    font-size: 0.8rem;
  }

}

@media screen and (max-width: 30em) {
  .btn {
    font-size: 0.825rem;
    padding: 0.95rem 1.95rem;
  }
  .btn-sm {
    font-size: 0.575rem;
    padding: 0.1rem 0.35rem
  }
  .btn.btn-filter {
    font-size: 0.7rem;
  }
}
