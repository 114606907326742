.section__py--xl {
  padding: 3rem 0;
}

.page__padding--top {
  padding-top: 6.5rem;
}

.block__context--primary {
  background-color: $color__pale-blue !important;
}
