@media screen and (max-width: 64em) {
  body {
    font-size: 0.75rem;
  }
  h1 {
    font-size: 3.375rem;
  }
  h2 {
    font-size: 2.25rem;
  }
  h3 {
    font-size: 1.6875rem;
  }
  h4 {
    font-size: 1.125rem;
  }
  h5 {
    font-size: 0.825rem;
  }
}

@media screen and (max-width: 50em) {
  body {
    font-size: 0.75rem;
  }
  h1 {
    font-size: 3.375rem;
  }
  h2 {
    font-size: 2.25rem;
  }
  h3 {
    font-size: 1.6875rem;
  }
  h4 {
    font-size: 1.125rem;
  }
  h5 {
    font-size: 0.825rem;
  }
}

@media screen and (max-width: 30em) {
  body {
    font-size: 0.75rem;
  }
  h1 {
    font-size: 3.375rem;
  }
  h2 {
    font-size: 2.25rem;
  }
  h3 {
    font-size: 1.6875rem;
  }
  h4 {
    font-size: 1.125rem;
  }
  h5 {
    font-size: 0.825rem;
  }
}
