//-------------------------
// 🎨 Colors
//-------------------------

// UI Color Scheme
$color__black: #000 !default;
$color__dark-grey: #3C3C3B !default;
$color__middle-grey: #545454 !default;
$color__grey: #B2B2B2 !default;
$color__light-grey: #EDEDED !default;
$color__white: #fff !default;

// UI Main Colors
$color__auerswald-blue: #008bc5 !default;
$color__dark-blue: #173447 !default;
$color__pale-blue: #d9effd !default;
$color__orange: #ed6b06 !default;
$color__middle-blue: #006ba8 !default;
$color__light-blue: #b7e2fa !default;
$color__white: #ffffff !default;

// UI Color Scheme Extended
$color__auerswald-blue-75: mix(white, $color__auerswald-blue, 25%) !default;
$color__auerswald-blue-50: mix(white, $color__auerswald-blue, 50%) !default;
$color__auerswald-blue-25: mix(white, $color__auerswald-blue, 75%) !default;
$color__auerswald-blue-10: mix(white, $color__auerswald-blue, 90%) !default;

$color__dark-blue-75: mix(white, $color__dark-blue, 25%) !default;
$color__dark-blue-50: mix(white, $color__dark-blue, 50%) !default;
$color__dark-blue-25: mix(white, $color__dark-blue, 75%) !default;
$color__dark-blue-10: mix(white, $color__dark-blue, 90%) !default;

$color__pale-blue-75: mix(white, $color__pale-blue, 25%) !default;
$color__pale-blue-50: mix(white, $color__pale-blue, 50%) !default;
$color__pale-blue-25: mix(white, $color__pale-blue, 75%) !default;
$color__pale-blue-10: mix(white, $color__pale-blue, 90%) !default;

$color__orange-75: mix(white, $color__orange, 25%) !default;
$color__orange-50: mix(white, $color__orange, 50%) !default;
$color__orange-25: mix(white, $color__orange, 75%) !default;
$color__orange-10: mix(white, $color__orange, 90%) !default;

$color__middle-blue-75: mix(white, $color__middle-blue, 25%) !default;
$color__middle-blue-50: mix(white, $color__middle-blue, 50%) !default;
$color__middle-blue-25: mix(white, $color__middle-blue, 75%) !default;
$color__middle-blue-10: mix(white, $color__middle-blue, 90%) !default;

$color__light-blue-75: mix(white, $color__light-blue, 25%) !default;
$color__light-blue-50: mix(white, $color__light-blue, 50%) !default;
$color__light-blue-25: mix(white, $color__light-blue, 75%) !default;
$color__light-blue-10: mix(white, $color__light-blue, 90%) !default;

// Deprecated
$mobile-icon-color: #bcbcbc;
