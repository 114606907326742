@media screen and (max-width: 64em) {
}
.form-control {
  font-size: 0.9rem;
}


@media screen and (max-width: 50em) {
  .form-control {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 30em) {
  .form-control {
    font-size: 0.7rem;
  }
}
