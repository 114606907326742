.badge {
    &.badge-bubble {
        position: relative;
        
        &:before {
            content: ' ';
            position: absolute;
            width: 0;
            height: 0;
            right: 20px;
            top: 100%;
            border: 8px solid;
            border-color: black transparent transparent black;
            border-bottom-left-radius: 5px;
        }
    }

    &.badge-primary {
        &.badge-bubble {
            &:before {
                border-color: $color__auerswald-blue transparent transparent $color__auerswald-blue;
            }
        }
    }

    &.badge-secondary {
        &.badge-bubble {
            &:before {
                border-color: $color__dark-blue transparent transparent $color__dark-blue;
            }
        }
    }

    &.badge-orange {
        color: white;

        &.badge-bubble {
            &:before {
                border-color: $color__orange transparent transparent $color__orange;
            }
        }
    }
}