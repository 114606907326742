/* TOP SELLER COMPONENT */

.topseller {
  /*
      code by Iatek LLC 2018 - CC 2.0 License - Attribution required
      code customized by Azmind.com
  */
  @media (min-width: 768px) and (max-width: 991px) {
      /* Show 4th slide on md if col-md-4*/
      & .carousel-inner .active.col-md-4.carousel-item + .carousel-item + .carousel-item + .carousel-item {
          position: absolute;
          top: 0;
          right: -33.3333%;  /*change this with javascript in the future*/
          z-index: -1;
          display: block;
          visibility: visible;
      }
  }
  @media (min-width: 576px) and (max-width: 768px) {
      /* Show 3rd slide on sm if col-sm-6*/
      & .carousel-inner .active.col-sm-6.carousel-item + .carousel-item + .carousel-item {
          position: absolute;
          top: 0;
          right: -50%;  /*change this with javascript in the future*/
          z-index: -1;
          display: block;
          visibility: visible;
      }
  }
  @media (min-width: 576px) {
      & .carousel-item {
          margin-right: 0;
      }
      /* show 2 items */
      & .carousel-inner .active + .carousel-item {
          display: block;
      }
      & .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
      & .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
          transition: none;
      }
      & .carousel-inner .carousel-item-next {
          position: relative;
          transform: translate3d(0, 0, 0);
      }
      /* left or forward direction */
      & .active.carousel-item-left + .carousel-item-next.carousel-item-left,
      & .carousel-item-next.carousel-item-left + .carousel-item,
      & .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item {
          position: relative;
          transform: translate3d(-100%, 0, 0);
          visibility: visible;
      }
      /* farthest right hidden item must be also positioned for animations */
      & .carousel-inner .carousel-item-prev.carousel-item-right {
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          display: block;
          visibility: visible;
      }
      /* right or prev direction */
      & .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
      & .carousel-item-prev.carousel-item-right + .carousel-item,
      & .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
          position: relative;
          transform: translate3d(100%, 0, 0);
          visibility: visible;
          display: block;
          visibility: visible;
      }
  }
  /* MD */
  @media (min-width: 768px) {
      /* show 3rd of 3 item slide */
      & .carousel-inner .active + .carousel-item + .carousel-item {
          display: block;
      }
      & .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
          transition: none;
      }
      & .carousel-inner .carousel-item-next {
          position: relative;
          transform: translate3d(0, 0, 0);
      }
      /* left or forward direction */
      & .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
          position: relative;
          transform: translate3d(-100%, 0, 0);
          visibility: visible;
      }
      /* right or prev direction */
      & .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
          position: relative;
          transform: translate3d(100%, 0, 0);
          visibility: visible;
          display: block;
          visibility: visible;
      }
  }
  /* LG */
  @media (min-width: 991px) {
      /* show 4th item */
      & .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
          display: block;
      }
      & .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
          transition: none;
      }
      /* Show 5th slide on lg if col-lg-3 */
      & .carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
          position: absolute;
          top: 0;
          right: -25%;  /*change this with javascript in the future*/
          z-index: -1;
          display: block;
          visibility: visible;
      }
      /* left or forward direction */
      & .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
          position: relative;
          transform: translate3d(-100%, 0, 0);
          visibility: visible;
      }
      /* right or prev direction //t - previous slide direction last item animation fix */
      & .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
          position: relative;
          transform: translate3d(100%, 0, 0);
          visibility: visible;
          display: block;
          visibility: visible;
      }
  }

  // Bugfix #1132 - TopSeller minHeight
  & .carousel-inner {
    @include media-breakpoint-up(lg) {
      min-height: 500px;
    }

    &.no-tags {
      @include media-breakpoint-up(lg) {
        min-height: 350px;
      }
    }

    &.no-tags.no-teaser {
      @include media-breakpoint-up(lg) {
        min-height: inherit;
      }
    }
  }

  &__padding {
    padding: 3rem 0;
  }

  &__headline {
    margin-bottom: 0;
  }

  &__nav {

    & > .badges {
      margin-right: 0.5rem;
    }

    & > .nav-link.active {
      background-color: $white;
      color: theme-color('primary');
    }
  }

  &__content {
    @extend .tab-content;

    & > .active {
      //display: flex;
    }
  }

  &__tabcontent {
    @extend .tab-pane;
  }

  &__hr {
    border-top: 1px solid rgba(255, 255, 255, 0.7);
    margin-top: .5rem;
  }

  &-height {
    height: 25rem;
  }
}
